import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
    return (
        <div className="footer-main">
        <div className="container">
            <div className="row footer-row">
                {/* <div className="col-sm-3 footer">
                    <p>Main Menu</p>
                        <ul>
                            <li>Home</li>
                            <li>About US</li>
                            <li>Contact Us</li>
                            <li>Products</li>
                            <li>Services</li>
                        </ul>
                </div>
                <div className="col-sm-3 footer">
                    <p>Services</p>
                        <ul>
                            <li>Request a Quote</li>
                            <li>Upload a File</li>
                            <li>Designing Services</li>
                            <li>Terms and Conditions</li>
                            <li>Privacy Policy</li>
                        </ul>
                </div> */}
                <div className="col-sm-6 footer">
                    <p>PRODUCTS</p>
                    <ul>
    <li><Link className="anchor-footer" to="/design/1">Business Stationery</Link></li>
    <li><Link className="anchor-footer" to="/design/2">Marketing Collateral</Link></li>
    <li><Link className="anchor-footer" to="/design/3">Gifts</Link></li>
    <li><Link className="anchor-footer" to="/design/4">Decor</Link></li>
</ul>

                </div>
                <div className="col-sm-6 footer">
                    <p>CONTACT</p>
                    <ul>
                     <li>Catalyst House </li>
                       <li> 720 Centennial Court, Centennial Park</li>
                        <li>Elstree, Hertfordshire WD6 3SY</li>
                       
                        <li> 020 3375 3364 | info@inndus.com</li>
                        
                    </ul>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Footer;


